<template>
  <div>
    <div v-if="convertTokoOrder">
      <h2 class="headline--md text-bold text-uppercase">detail of convert toko</h2>

      <div class="box box--md box--white mt-12">
        <table class="table table--list three-items">
          <tbody>
            <tr>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Email</p>
                  <div class="clipboard-copy">
                    <h2 class="headline--md mt-4 clipboard-copy__text d-inline-block">
                      <span class="clipboard-copy__text-inner">{{ convertTokoOrder.user.email }}</span>
                      <copy-clipboard :is-external="false" @on-copy="doCopy($event)" />
                    </h2>
                  </div>
                </div>
              </td>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Full Name</p>
                  <h2 class="headline--md mt-4">{{ convertTokoOrder.user.fullName }}</h2>
                </div>
              </td>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Mobile Number</p>
                  <h2 class="headline--md mt-4">
                    {{ convertTokoOrder.user.countryCode }} {{ convertTokoOrder.user.phone }}
                  </h2>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Convert Amount (TOKO)</p>
                  <h2 class="headline--md mt-4">{{ convertTokoOrder.amount | float_number_with_commas }}</h2>
                </div>
              </td>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Admin Receive At</p>
                  <h2 v-if="convertTokoOrder.adminReceivedAtMsc > 0" class="headline--md mt-4">
                    {{ convertTokoOrder.adminReceivedAtMsc | moment_lts }}
                  </h2>
                  <h2 v-else>_</h2>
                </div>
              </td>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Admin Send At</p>
                  <h2 v-if="convertTokoOrder.adminSendAtMsc > 0" class="headline--md mt-4">
                    {{ convertTokoOrder.adminSendAtMsc | moment_lts }}
                  </h2>
                  <h2 v-else>_</h2>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">From -> To</p>
                  <div v-if="convertTokoOrder.fromNetwork === 1" class="d-flex justify-start mt-4">
                    <toko-eth-icon /> <span class="mr-8 ml-8">></span> <toko-bsc-icon />
                  </div>

                  <div v-else class="d-flex justify-start mt-4">
                    <toko-bsc-icon /> <span class="mr-8 ml-8">></span> <toko-eth-icon />
                  </div>
                </div>
              </td>

              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Status</p>
                  <h2 v-html="formatConvertTokoOrderStatus(convertTokoOrder.status)" class="headline--md mt-4" />
                </div>
              </td>

              <td />
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="convertTokoTnxFrom" class="mt-32">
      <h2 class="headline--md text-bold text-uppercase">User transfer information</h2>
      <transaction :show-user="false" :transaction="convertTokoTnxFrom" />
    </div>

    <div v-if="convertTokoTnxTo" class="mt-32">
      <h2 class="headline--md text-bold text-uppercase">User receive information</h2>
      <transaction :show-user="false" :transaction="convertTokoTnxTo" />
    </div>

    <loading :open="!isLoaded" />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import toggleLoading from '@/mixins/toggle-loading'
import copyClipboard from '@/mixins/copy-clipboard'
import convertTokoOrderStatus from './-mixins/convert-toko-order-status'
const Transaction = () => import('@/components/Transaction')
const CopyClipboard = () => import('@/components/CopyClipboard')
const TokoBscIcon = () => import('@/ui/toko-icons/TokoBSC')
const TokoEthIcon = () => import('@/ui/toko-icons/TokoETH')

export default {
  mixins: [toggleLoading, copyClipboard, convertTokoOrderStatus],

  computed: {
    ...mapState('convertToko', ['convertTokoOrder', 'convertTokoTnxFrom', 'convertTokoTnxTo'])
  },

  components: {
    Transaction,
    CopyClipboard,
    TokoBscIcon,
    TokoEthIcon
  },

  methods: {
    ...mapActions('convertToko', ['getConvertTokoOrder']),

    ...mapMutations('convertToko', ['CLEAR_CONVERT_TOKO_ORDER'])
  },

  async created() {
    this.CLEAR_CONVERT_TOKO_ORDER()
    try {
      await this.getConvertTokoOrder(this.$route.params.id)
      this.toggleLoading()
    } catch (e) {
      this.toggleLoading()
      throw e
    }
  }
}
</script>
